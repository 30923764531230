import {
  Button,
  ErrorMessage,
  Form,
  HR,
  Input,
  OverviewTitle,
  Span,
} from '@aurelius/components'
import { Box } from '@chakra-ui/react'
import { Logo } from '@aurelius/icons'
import { loginType, login as loginValidation } from '@aurelius/models'
import { FetchError } from '@aurelius/utils'
import styled from '@emotion/styled'
import { yupResolver } from '@hookform/resolvers/yup'
import Link from 'next/link'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { getUserFromCookie, isAuthenticated, login } from '../services/auth'
import { Hint } from './Hint'
import { UserProfile } from '../services/profile'
import { useEffectOnce } from 'react-use'

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > * {
    width: 400px;
  }
`

export const Login = ({
  onLogin,
}: {
  onLogin?: (user: UserProfile) => void
}) => {
  const [{ ready, error, loading, showResubscribeMessage }, setState] =
    useState<{
      error?: Error
      loading: boolean
      showResubscribeMessage: boolean
      ready: boolean
    }>({
      error: undefined,
      loading: false,
      showResubscribeMessage: false,
      ready: false,
    })

  useEffectOnce(() => {
    setState((prev) => ({ ...prev, ready: true }))
  })

  // login function
  const doLogin = async ({ email, password }: loginType): Promise<void> => {
    try {
      setState((s) => ({
        ...s,
        loading: true,
        error: undefined,
        showResubscribeMessage: false,
      }))

      await login(email, password)

      const loggedIn = isAuthenticated()
      if (loggedIn) {
        if (onLogin != null) {
          const userProfile = getUserFromCookie()
          if (userProfile) {
            onLogin(userProfile)
          } else {
            setState((s) => ({
              ...s,
              loading: false,
              error: new Error('Cannot parse user.'),
              showResubscribeMessage: false,
            }))
          }
        }
      } else {
        setState((s) => ({
          ...s,
          loading: false,
          error: new Error('Not authenticated.'),
          showResubscribeMessage: false,
        }))
      }
    } catch (ex) {
      if (ex.message === 'Payment required.') {
        setState((s) => ({
          ...s,
          loading: false,
          showResubscribeMessage: true,
          error: undefined,
        }))
      } else {
        console.error(ex)
        setState((s) => ({
          ...s,
          loading: false,
          error: ex,
          showResubscribeMessage: false,
        }))
      }
    }
  }
  const { register, handleSubmit, errors } = useForm<loginType>({
    mode: 'onBlur',
    resolver: yupResolver(loginValidation),
  })

  return (
    <Box maxWidth="544px" margin="auto">
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        <Box margin="0 auto 40px">
          <Logo width={100} height={100} />
        </Box>
        <OverviewTitle marginBottom="56px">Sign in</OverviewTitle>
      </Box>

      <Form
        autoComplete="off"
        onSubmit={handleSubmit(doLogin)}
        alignItems="center"
        display="flex"
        flexDirection="column"
        disabled={!ready}
      >
        <InputWrapper display="flex" flexDirection="column" marginBottom="18px">
          <Input
            ref={register}
            type="email"
            id="email"
            name="email"
            label="Email"
            placeholder=""
            error={errors.email?.message}
          />
        </InputWrapper>
        <InputWrapper display="flex" flexDirection="column" marginBottom="18px">
          <Input
            ref={register}
            type="password"
            id="password"
            name="password"
            label="Password"
            placeholder=""
            error={errors.password?.message}
          />
        </InputWrapper>
        <InputWrapper display="flex" marginBottom="30px">
          <Button
            type="submit"
            flexGrow={1}
            loading={loading}
            disabled={!ready}
          >
            Sign in
          </Button>
        </InputWrapper>
        <HR width="400px" margin="50px 0 20px 0" />
        <Box display="flex" justifyContent="center" alignItems="center">
          <Link href="/sso" className="anchor">
            Sign in with SSO
          </Link>
          <Span margin="0 8px">•</Span>
          <Link href="/reset" className="anchor">
            Forgot Password?
          </Link>
        </Box>
        {showResubscribeMessage && (
          <Hint width="100%">
            Your account is currently inactive.
            <br />
            <br />
            To sign in,{' '}
            <Link href={`${process.env.MARKETING_URL}/pricing`}>
              choose a plan and enter payment details
            </Link>
            .
          </Hint>
        )}
        <ErrorMessage marginTop="8px" display="flex" justifyContent="center">
          <>
            {error?.message}
            {(error as FetchError)?.statusCode === 403 && (
              <span>
                Please{' '}
                <Link href="/sso" className="anchor">
                  sign in
                </Link>{' '}
                using SSO.
              </span>
            )}
          </>
        </ErrorMessage>
      </Form>
    </Box>
  )
}

Login.displayName = 'Login'
