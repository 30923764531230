import { HTMLAttributes } from 'react'
import { Box, BoxProps } from '@chakra-ui/react'

export const Form = ({
  children,
  ...props
}: HTMLAttributes<HTMLFormElement> &
  BoxProps & { autoComplete?: string; disabled?: boolean }) => (
  <Box as="form" {...props}>
    {children}
  </Box>
)

export default Form
