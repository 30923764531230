import { useState } from 'react'
import { Modal, ModalWrapper, CloseModal } from '../Modal'
import { Box } from '@chakra-ui/react'
import { Button } from '../Button'
import styled from '@emotion/styled'
import { ErrorMessage } from '../ErrorMessage'
import { P } from '../Typography'
import { DocumentPreview } from './DocumentPreview'
import {
  Document,
  DocumentType,
  getDocumentTypeFromMime,
} from '@aurelius/models'
import { downloadFile } from '@aurelius/utils'

const CustomParagraph = styled(P)`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.14px;
  color: var(--colors-typography-meta);
`

export interface DocumentPreviewModalProps {
  document: Document
  close: VoidFunction
  type: DocumentType
  title?: string
  file: string
  thumbnail?: string
}

export const DocumentPreviewModal = ({
  document,
  close,
  title,
  file,
  thumbnail,
}: DocumentPreviewModalProps) => {
  const [downloading, setDownloading] = useState(false)
  const [error, setError] = useState()

  const download = (): void => {
    try {
      setDownloading(true)

      downloadFile(file, title ?? document.filename, document.mimetype)
    } catch (ex) {
      console.error(ex)
      setError(ex.message)
    } finally {
      setDownloading(false)
    }
  }

  return (
    <Modal>
      <ModalWrapper>
        <CloseModal onClick={close} />
        <Box margin="40px">
          <Box
            display="flex"
            flex="1"
            alignItems="center"
            justifyContent="center"
            width="100%"
            flexDirection="column"
          >
            <CustomParagraph marginBottom="16px">{title}</CustomParagraph>

            <DocumentPreview
              preview={false}
              thumbnail={thumbnail}
              file={file}
              type={getDocumentTypeFromMime(document?.mimetype ?? '')}
              name={document?.name || 'Document Full Preview'}
              duration={document.duration}
            />
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            height="44px"
            justifyContent="flex-end"
            marginTop="12px"
          >
            <Button type="reset" onClick={close} marginRight="32px">
              Close
            </Button>
            <Button
              type="submit"
              onClick={download}
              loading={downloading}
              marginRight="32px"
            >
              Download
            </Button>
          </Box>
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </Box>
      </ModalWrapper>
    </Modal>
  )
}
