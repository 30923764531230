import download from 'js-file-download'
import fetchRetry from 'fetch-retry'
import { toast } from 'react-toastify'

const fetch = fetchRetry(
  typeof window !== 'undefined' ? window.fetch : global.fetch,
)
export class FetchError extends Error {
  readonly statusCode: number | undefined
  readonly name: string

  constructor(m: string, statusCode: number, name: string) {
    super(m)
    this.name = name
    this.statusCode = statusCode
    this.message = m

    Object.setPrototypeOf(this, FetchError.prototype)
  }
}

export async function handleResponseStatusAndContentType(
  response: Response,
): Promise<any> {
  const contentType = response?.headers?.get('content-type')

  if (response?.status === 401) {
    throw new Error('Request was not authorized.')
  }

  if (contentType === null) {
    return null
  } else if (contentType.includes('application/json')) {
    return response.json()
  } else if (
    contentType.includes('text/plain') ||
    contentType.includes('text/html')
  ) {
    return response.text()
  } else if (contentType.includes('text/file')) {
    const header = response.headers.get('content-disposition')
    const filename = header?.split('filename=')[1]
    const blob = await response.blob()
    return download(blob, filename ?? 'file', 'text/file')
  } else if (
    contentType.includes(
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    )
  ) {
    const header = response.headers.get('content-disposition')
    const filename = header?.split('filename=')[1]
    const blob = await response.blob()
    return download(blob, filename ?? 'file', contentType)
  } else {
    throw new Error(`Unsupported response content-type: ${contentType}`)
  }
}

const defaultOptions = {
  method: 'GET',
}

/**
 * @template T The type of the response.
 * @param url The URL of the resource
 * @param options Any options to pass to fetch
 * @param body The body, as JSON, which will be automatically stringified
 * @returns Promise<T>
 */
export async function fetchAPI<T>(
  url: string,
  options: Record<string, any> = defaultOptions,
  body?: Record<string, any>,
): Promise<T> {
  const controller = new AbortController()

  // TODO:Use a library such as 'ms'
  const fiveMinutes = 60_000 * 5
  const timeout = setTimeout(() => {
    controller.abort()
  }, fiveMinutes)

  const ssr = typeof window === 'undefined'

  const finalUrl =
    url.indexOf('https://') > -1 || url.indexOf('http://') > -1
      ? url // full url already passed in
      : `${
          (typeof window === 'undefined' && process.env.API_BASE_SSR
            ? process.env.API_BASE_SSR
            : process.env.API_BASE) ?? '' // mac local dev needs different backend address for some reason
        }${url}`

  const response = await fetch(finalUrl, {
    retryDelay: (attempt) => Math.pow(2, attempt) * 1000,
    retryOn: (attempt, error, response) => {
      if (error?.name === 'AbortError') {
        // fetch aborted
        return false
      } else if (response?.status == 401) {
        // TODO: Update UserContext to have user null or just log out some how
        // not authorized
        return false
      } else {
        // retry on any network error, or 503/502 status codes, not on ssr
        if (
          !ssr &&
          attempt < 5 &&
          (error !== null || response?.status == 503 || response?.status == 502)
        ) {
          console.info(`fetch retrying, attempt number ${attempt + 1}`)
          return true
        } else {
          return false
        }
      }
    },
    credentials: 'include',
    mode: 'cors',
    body: JSON.stringify(body),
    signal: controller.signal,
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options.headers,
    },
  }).finally(() => {
    clearTimeout(timeout)
  })
  if (!response.ok) {
    const clonedResponse = response.clone() // Clone the response
    let errorBody
    try {
      // Attempt to parse the body as JSON from the original response
      errorBody = await response.json()
    } catch (e) {
      // If JSON parsing fails, use the cloned response to read as text
      const errorMessage = await clonedResponse.text()
      errorBody = { message: errorMessage, statusCode: response.status }
    }

    const err = new FetchError(
      errorBody.message,
      errorBody.statusCode ?? response.status,
      errorBody.name ?? 'FetchError', // Adjust the default error name as necessary
    )
    // console.error(err)
    throw err
  }

  return (await handleResponseStatusAndContentType(response)) as Promise<T>
}

export async function fetchStream(
  url: string,
  options: Record<string, any> = defaultOptions,
  onChunk: Function,
  onDone: Function,
): Promise<void> {
  const response = await fetch(`${process.env.API_BASE}` + url, {
    credentials: 'include',
    mode: 'cors',
    ...options,
  })

  if (!response) {
    throw new Error('No response from server')
  }
  const reader = response?.body?.getReader()

  if (reader) {
    //eslint-disable-next-line
    while (true) {
      const { value, done } = await reader.read()
      if (done) {
        break
      }
      onChunk(value)
    }

    onDone()
  }
}

const downloadUsingAnchor = (
  request: string,
  filename: string,
  mimetype: string,
): void => {
  const link = document.createElement('a')
  link.href = request
  link.download = filename
  link.type = mimetype
  link.target = '_blank'
  link.rel = 'noopener noreferrer'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const downloadFileWithFileSystemAPI = async (
  url: string,
  suggestedFileName: string,
) => {
  try {
    if ('showSaveFilePicker' in window) {
      //@ts-expect-error We just checked
      const fileHandle = await window.showSaveFilePicker({
        suggestedName: suggestedFileName,
      })

      if (!fileHandle) {
        console.warn('File save cancelled by the user.')
        return
      }
      const writableStream = await fileHandle.createWritable()
      const response = await fetch(url, {
        mode: 'cors',
      })
      if (!response.body) {
        throw new Error('ReadableStream not supported for this browser')
      }
      const reader = response.body.getReader()

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { done, value } = await reader.read()
        if (done) {
          break
        }
        await writableStream.write(value)
      }

      await writableStream.close()

      console.info(`File successfully saved to ${fileHandle.name}`)

      toast.success('File downloaded successfully.')
    }
  } catch (error) {
    console.error(
      'Error downloading and saving file using File System API:',
      error,
    )
    toast.error(`File download failed: ${error.message ?? 'Unknown error.'}`)
  }
}

export const downloadFile = (
  request: string,
  filename: string,
  mimetype: string,
): void => {
  if ('showSaveFilePicker' in window) {
    downloadFileWithFileSystemAPI(request, filename)
  } else {
    downloadUsingAnchor(request, filename, mimetype)
  }
}

export const retryFailOn404 = (_failureCount: number, error: FetchError) =>
  error.statusCode !== 404

export default fetchAPI
